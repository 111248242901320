import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@pages/auth/services/auth.service';
import { UtilRuta } from '../utils/ruta.util';

export const hasRolGuard: CanActivateFn = (route, state) => {
  const authSvc = inject(AuthService);
  const _router = inject(Router);
  if (!authSvc.dataUsuario) {
    _router.navigate(['login']);
    return false;
  }
  const autorizado = (route.data['rol'] as string[]).includes(authSvc.dataUsuario.rol.id.toString());
  if (!autorizado) {
    const ruta = UtilRuta.irRutaPrincipal(authSvc.dataUsuario.rol.id);
    _router.navigate(ruta);
    return false;
  }
  return true;
};
